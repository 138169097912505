a {
    color: var(--primary-color);
    text-decoration: none;
}

.custom-top-margin-input {
    margin-top: 1.25rem;
    /* 20px */
}

.custom-tb-button-margin {
    margin-top: 2rem !important;
    /* 30px */
    margin-bottom: 2rem;
    /* 30px */
}

/*Input change for IWI (Input without icon) */
.iwi input {
    background: transparent !important;
    padding-left: 3rem !important;
    width: 100% !important;
}

/* 30px margin in rem */
.m-30 {
    margin-top: 2.15rem !important;
    margin-bottom: 2.15rem !important;
}

/* 14px font-size */
.p-component .fs-14 {
    font-size: 0.955rem !important;
}

/* custom title font */
.custom-title-font {
    font-size: 2rem !important;
}

/* auth text margin-top */
.auth-mt-16 {
    margin-top: 1.8571rem !important;
}

/* button font size */
.btn-font-size {
    font-size: 1.3333rem !important;
}

.hidden-input {
    display: none;
}

.p-fileupload span {
    border: 1px;
    border-radius: 50% !important;
}

.uupi .p-button.p-component {
    width: 42px;
    height: 42px;
    min-width: auto;
    background-color: var(--primary-color) !important;
}

.uupi .p-button svg {
    margin: 0;
}

.uupi .p-fileupload {
    right: -5px;
    bottom: 5px;
}

.uupi .p-fileupload .p-button-label {
    display: none;
}

.wakandi-icon {
    width: calc(61px - 15.25px) !important;
}

.margin-to-position {
    margin-top: -26% !important;
}

@media screen and (max-width: 768px) {
    .mq-526 {
        width: 100% !important;
    }

    .mq-526-90-percent {
        width: 90% !important;
    }

    .profile-pic {
        width: 100px !important;
        height: 100px !important;
    }

    .logo-on-526 {
        width: calc(61px - 15.25px) !important;
    }

    .top-space-on-526 {
        margin-top: 0px !important;
    }

    .margin-top-768 {
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 768px) {
    .top60 {
        margin-top: 5rem !important;
    }
}

span.countryCode {
    position: absolute;
    left: 33px !important;
    top: 23px;
    line-height: 0;
}


input.extraPadding {
    padding-left: 5rem !important;
}



.main-app-base {
    max-width: 430px;
    margin: 0 auto;
    width: 100%;
}

.base-component {
    background: #63a094;
    border-radius: var(--defaultBorderRadius);
    text-align: center;
    color: var(--white);
    padding: 30px 15px 120px;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-content {
    margin: 0 15px;
    margin-top: -120px;
}

.mt-15px {
    margin-top: 15px;
}

.mb-30px {
    margin-bottom: 30px;
}

.content-align-center-page {
    display: flex;
    min-height: 100vh;
    align-items: center;
}

.main-title {
    font-size: 1.71rem;
}

.color-primary {
    color: var(--primary-color);
}

/**
  override css
  **/

.p-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000033;
    border-radius: 4px;
    padding: 20px 15px 30px;
}

.p-card .p-card-body {
    padding: 0;
}

.p-card .p-card-body .p-card-content {
    padding: 0;
}


.p-checkbox-label {
    color: var(--grey-wk);
}

.p-button.p-component {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--btn-fontSize);
}

.p-input-icon-left>i:first-of-type {
    z-index: 999;
    top: 23px;
}

input.p-component {
    border-color: var(--grey-light-wk);
}

input.p-component::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey-light-wk);
    opacity: 1;
    /* Firefox */
}

input.p-component:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey-light-wk);
}

input.p-component ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey-light-wk);
}

.p-input-icon-left>.p-dropdown {
    padding-left: 3rem;
}

.p-input-icon-left .p-dropdown-label {
    padding-left: 0;
}

@keyframes p-progress-spinner-color {

    100%,
    0% {
        stroke: #F2FBFB;
    }

    40% {
        stroke: #63B5B4;
    }

    66% {
        stroke: #138F8D;
    }

    80%,
    90% {
        stroke: var(--primary-color);
    }
}


@media(max-width: 560px) {
    .content-align-center-page {
        align-items: start;
    }

    .main-app-base {
        width: 100%;
        max-width: 100%;
        margin-top: 0 !important;
    }

    .base-component {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .uupi .p-button.p-component {
        height: 30px;
        width: 30px;
    }

    .p-fileupload .p-button.p-component span {
        font-size: .8rem;
    }
}

.inputCountryCode {
    padding-left: 5rem !important;
}

.inputPassword {
    margin-left: -2rem !important;
}

::-ms-reveal { display: none; }

.otp-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.otp-input[type=number] {
    -moz-appearance: textfield
}
