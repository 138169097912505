@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "/src/Assets/style/theme.css";
@import "/node_modules/primeflex/primeflex.css";
@import "/src/Assets/style/style.css";

:root {
  --primary-color: #057573;
  --white: #ffffff;
  --defaultBorderRadius: 8px;
  --grey-wk: #666666;
  --btn-fontSize: 16px;
  --grey-light-wk: #9E9E9E;
}

html {
  font-size: 14px;
}

body,
.p-component {
  font-weight: normal;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

body {
  margin: 0;
}